import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import React, { useState } from "react";

export function MainBanner() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Container id="main-banner" className="main-banner wow fadeIn animated">
      <Row>
        <Col lg="12">
          <div className="player-wrapper">
            <ReactPlayer
              className="background-video"
              url="https://youtu.be/I4VX0DN1OMw"
              playing={!show}
              loop
              muted
              width="100%"
              height="150%"
              data-wow-duration="1s"
              data-wow-delay="0.1s"
            />
          </div>
          <div className="overlay">
            <div
              className="left-content header-text wow fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay="1s"
            >
              <h2>
                Indépendant et salarié, <br />
                c'est possible !
              </h2>

              <p>
                <b>Pourquoi choisir le portage salarial ?</b>
                <br />
                Le portage salarial est une forme d'emploi impliquant une
                relation tripartite entre un travailleur indépendant, un client
                et une entreprise de portage salarial.
                <br />
                Cette solution est à mi-chemin entre entrepreneur et salarié.
                Elle vous permettra de développer votre activité professionnelle
                indépendante, tout en conservant la couverture sociale d’un
                salarié classique.
                <br />
                Le portage salarial vous laissera la liberté et le choix
                d’orienter votre carrière comme vous le souhaitez.
                <br />
                <br />
                <b>Notre solution ?</b>
                <br />
                Vous accompagner dans chaque étape de votre vie pour réaliser
                vos ambitions.
                <br />
                <br />
              </p>
              <Button
                className="main-banner-button btn btn-primary"
                onClick={handleShow}
              >
                Voir notre film
              </Button>
            </div>
          </div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton />
            <Modal.Body>
              <Row>
                <Col lg={{ span: 8, offset: 2 }}>
                  <div
                    className="section-heading wow bounceIn"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <h2>
                      Monte sur le <em>pont</em>
                    </h2>
                  </div>
                </Col>
              </Row>
              <ReactPlayer
                url="https://youtu.be/OBuZD8dCb2Q"
                width="100%"
                height="480px"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
                playing={show}
              />
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
}
