import React from "react";
import { Button, Spinner } from "react-bootstrap";

interface Props {
  isLoading: boolean;
  name: string;
}

export function LoadingButton(props: Props) {
  return (
    <Button variant="primary" type="submit" disabled={props.isLoading}>
      {!props.isLoading && props.name}
      {props.isLoading && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
    </Button>
  );
}
