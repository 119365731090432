import React from "react";
import { Card, Container } from "react-bootstrap";
import { card } from "../../constants/styles";
import { SalarySimulatorForm } from "./SalarySimulatorForm";
import { SalarySimulatorTable } from "./SalarySimulatorTable";

export function SalarySimulator() {
  return (
    <Container id={"simulator"}>
      <Card style={card}>
        <Card.Body>
          <SalarySimulatorForm />
          <SalarySimulatorTable />
        </Card.Body>
      </Card>
    </Container>
  );
}
