import React from "react";
import { Row, Col, Container } from "react-bootstrap";

export function OurExpertise() {
  return (
    <Container id={"features"} className="features section">
      <Row>
        <Col lg={{ span: 6, offset: 3 }}>
          <div className="section-heading wow bounceIn">
            <h6>Nos Atouts</h6>
            <h2>
              Envie de vous <span>dépasser</span> ? <br />
              Notre accompagnement <em>sur mesure</em> vous le permettra
            </h2>
            <br />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <div className="features-content">
            <Row>
              <Col lg="4">
                <div
                  className="features-item first-feature wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0s"
                >
                  <div className="first-number number">
                    <h6>01</h6>
                  </div>
                  <div className="icon" />
                  <h4>Besoin de formation ?</h4>
                  <div className="line-dec" />
                  <p>
                    Des formations certifiantes et éligibles au CPF sont à votre
                    disposition.
                    <br />
                    Que ce soit pour développer votre activité d’indépendant ou
                    perfectionner vos connaissances, nous serons à votre côté
                    pour vous accompagner.​
                  </p>
                </div>
              </Col>
              <Col lg="4">
                <div
                  className="features-item second-feature wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0s"
                >
                  <div className="second-number number">
                    <h6>02</h6>
                  </div>
                  <div className="icon" />
                  <h4>Boostez votre profil</h4>
                  <div className="line-dec" />
                  <p>
                    Nos ateliers vous proposerons de mettre en valeur votre
                    expérience professionnelle sur les réseaux sociaux mais
                    aussi auprès de vos contacts existants.
                    <br />
                    Ainsi, vous serez réactif et performant lors de votre
                    prochaine recherche de mission.​
                  </p>
                </div>
              </Col>
              <Col lg="4">
                <div
                  className="features-item third-feature wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0s"
                >
                  <div className="third-number number">
                    <h6>03</h6>
                  </div>
                  <div className="icon" />
                  <h4>Recherche de consultants</h4>
                  <div className="line-dec" />
                  <p>
                    Via notre groupe <a href="https://www.otchee.fr">OTCHEE</a>,
                    il vous sera mis à disposition les demandes de mission de
                    nos clients.
                    <br />
                    Vous serez libre d’y répondre et nous serons à votre
                    disposition si vous avez besoin d’un suivi personnalisé. ​
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
