/* eslint-disable */
/**
 * ALP API
 * A l\'aportage API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactForm
 */
export interface ContactForm  {
    /**
     * 
     * @type {string}
     * @memberof ContactForm
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ContactForm
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ContactForm
     */
    telephone?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactForm
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof ContactForm
     */
    visitorId?: string;
}

export function ContactFormFromJSON(json: any): ContactForm {
    return {
        'name': json['name'],
        'email': json['email'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'message': json['message'],
        'visitorId': !exists(json, 'visitor_id') ? undefined : json['visitor_id'],
    };
}

export function ContactFormToJSON(value?: ContactForm): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'email': value.email,
        'telephone': value.telephone,
        'message': value.message,
        'visitor_id': value.visitorId,
    };
}


