import React from "react";
import { useSelector } from "react-redux";
import { Col, Row, Container, Table } from "react-bootstrap";

const getSalarySimulation = (state: any) => state.entities.salarySimulator;

function Asterisk() {
  return <span style={{ color: "#ff0000" }}>*</span>;
}

const currencyFormat = new Intl.NumberFormat("fr-FR", {
  style: "currency",
  currency: "EUR",
});

export function SalarySimulatorTable() {
  const salarySimulator = useSelector(getSalarySimulation);

  if (salarySimulator === undefined) {
    return null;
  }

  return (
    <Container>
      <Row>
        <Col>
          <Table hover>
            <tbody>
              <tr>
                <td>Chiffre d'affaires (CA HT)</td>
                <td className={"text-center"}>
                  {currencyFormat.format(salarySimulator.revenue)}
                </td>
              </tr>
              <tr>
                <td>Commission A L'APORTAGE</td>
                <td className={"text-center"}>
                  {currencyFormat.format(salarySimulator.managementFees)}
                </td>
              </tr>
              <tr>
                <td>Salaire BRUT</td>
                <td className={"text-center"}>
                  {currencyFormat.format(salarySimulator.grossSalary)}
                </td>
              </tr>
              <tr>
                <td>Charges employeur</td>
                <td className={"text-center"}>
                  {currencyFormat.format(salarySimulator.totalEmployerExpenses)}
                </td>
              </tr>
              <tr>
                <td>Charges Salariales</td>
                <td className={"text-center"}>
                  {currencyFormat.format(salarySimulator.totalSocialCharges)}
                </td>
              </tr>
              <tr>
                <td>
                  Salaire NET (hors frais professionnels) <Asterisk />
                </td>
                <td className={"text-center"}>
                  {currencyFormat.format(
                    salarySimulator.netSalaryWithManagementFees
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  Salaire NET (avec frais professionnels) <Asterisk />
                </td>
                <td className={"text-center"}>
                  {currencyFormat.format(
                    salarySimulator.netSalaryWithoutManagementFees
                  )}
                </td>
              </tr>
              <tr>
                <td>Pourcentage reversé au consultant</td>
                <td className={"text-center"}>
                  {salarySimulator.percentagePaid}%
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <p style={{ textAlign: "right", fontSize: "0.8rem" }}>
            <Asterisk /> Salaire NET hors Prélèvement à la source
          </p>
        </Col>
      </Row>
    </Container>
  );
}
