import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import allandRobert from "../../assets/customers/logo-alland_robert.png";
import bnpParibas from "../../assets/customers/logo-BNP_paribas.png";
import cfm from "../../assets/customers/logo-cfm.png";
import disney from "../../assets/customers/logo-disney.png";
import enedis from "../../assets/customers/logo-enedis.png";
import engie from "../../assets/customers/logo-engie.png";
import grdf from "../../assets/customers/logo-GRDF.png";
import infogene from "../../assets/customers/logo-infogene.png";
import intm from "../../assets/customers/logo-intm.png";
import lOreal from "../../assets/customers/logo-L-Oreal.png";
import montrealAssociate from "../../assets/customers/logo-montreal_associate.png";
import regal from "../../assets/customers/logo-regal.png";
import saintGobin from "../../assets/customers/logo-Saint-Gobain.png";
import servier from "../../assets/customers/logo-Servier.png";
import stime from "../../assets/customers/logo-stime.jpg";
import systemU from "../../assets/customers/logo-systeme_u.png";
import thales from "../../assets/customers/logo-Thales.png";

export function Customers() {
  return (
    <Container className="section">
      <Row>
        <Col lg="12" className="Customers wow fadeInUp animated">
          <Col lg="3">
            <img src={allandRobert} alt="alland_robert" />
          </Col>
          <Col lg="3">
            <img src={bnpParibas} alt="BNP Paribas" />
          </Col>
          <Col lg="3">
            <img src={cfm} alt="CFM" />
          </Col>
          <Col lg="3">
            <img src={disney} alt="Disneyland" />
          </Col>
        </Col>
        <Col lg="12" className="Customers wow fadeInUp animated">
          <Col lg="3">
            <img src={enedis} alt="Enedis" />
          </Col>
          <Col lg="3">
            <img src={engie} alt="Engie" />
          </Col>
          <Col lg="3">
            <img src={grdf} alt="GRDF" />
          </Col>
          <Col lg="3">
            <img src={infogene} alt="Infogene" />
          </Col>
        </Col>
        <Col lg="12" className="Customers wow fadeInUp animated">
          <Col lg="3">
            <img src={intm} alt="Intmm Groupe" />
          </Col>
          <Col lg="3">
            <img src={lOreal} alt="L Oreal" />
          </Col>
          <Col lg="3">
            <img src={montrealAssociate} alt="Montreal Associate" />
          </Col>
          <Col lg="3">
            <img src={regal} alt="Regal &amp; Sens" />
          </Col>
        </Col>
        <Col lg="12" className="Customers wow fadeInUp animated">
          <Col lg="3">
            <img src={saintGobin} alt="Saint Gobain" />
          </Col>
          <Col lg="3">
            <img src={servier} alt="Servier" />
          </Col>
          <Col lg="3">
            <img src={stime} alt="Stime" />
          </Col>
          <Col lg="3">
            <img src={systemU} alt="Systeme U" />
          </Col>
        </Col>
        <Col lg="12" className="Customers wow fadeInUp animated">
          <Col lg="3">
            <img src={thales} alt="Thales" />
          </Col>
        </Col>
      </Row>
    </Container>
  );
}
