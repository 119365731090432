import React from "react";
import { Row, Col, Container } from "react-bootstrap";

export function OurNetwork() {
  return (
    <Container id={"network"} className="network section">
      <Row>
        <Col lg="6">
          <div
            className="wording align-self-center wow fadeInRight"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            <div className="section-heading">
              <h6>Notre réseau</h6>
              <h2>
                Au service de votre <span>expérience</span>
              </h2>
            </div>
            <b>
              Vous intégrez notre vivier d’experts et profitez de leurs
              expériences
            </b>
            <p>
              Lors de nos réunions d’informations, vous serez dans le partage du
              savoir être et de l’échange avec autrui afin d’optimiser vos
              relations professionnelles.
              <br />
              Chaque trimestre nous organisons des événements qui permettent
              d’échanger sur les différentes situations rencontrées par chacun
              mais aussi sur des solutions ou préconisations.
            </p>
            <br />
            <b>Vous êtes content de nos services ? Diffusez l’info ;-)</b>
            <p>
              En partageant votre expérience avec vos collègues, et en les
              cooptant, nous vous offrirons un week-end inoubliable pour 2.
            </p>
          </div>
        </Col>
        <Col lg="6">
          <div
            className="left-image wow fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          />
        </Col>
      </Row>
    </Container>
  );
}
