import {
  Modal,
  Row,
  Col,
  Button,
  Nav,
  Navbar,
  Container,
} from "react-bootstrap";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateEntities } from "redux-query";
import { SalarySimulator } from "./SalarySimulator";

export function Header() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  function handleClose() {
    setShow(false);
    dispatch(
      updateEntities({
        salarySimulator: () => undefined,
      })
    );
  }
  const handleShow = () => setShow(true);

  return (
    <Navbar
      id="header"
      className="header-area header-sticky wow slideInDown animated background-header main-nav"
      expand="lg"
    >
      <Container>
        <Navbar.Brand href="#" className="AL-logo" />
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link className="section-AL" href="#top">
              Accueil
            </Nav.Link>
            <Nav.Link className="section-AL" href="#services">
              Nos Services
            </Nav.Link>
            <Nav.Link className="section-AL" href="#features">
              Nos Atouts
            </Nav.Link>
            <Nav.Link className="section-AL" href="#about">
              Qui Sommes-Nous?
            </Nav.Link>
            <Nav.Link className="section-AL" href="#contact">
              Contact
            </Nav.Link>

            <Button
              className="main-header-button"
              variant="primary"
              onClick={handleShow}
            >
              Obtenir une Simulation
            </Button>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton />
              <Modal.Body>
                <Row>
                  <Col lg={{ span: 6, offset: 3 }}>
                    <div
                      className="section-heading wow bounceIn"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                    >
                      <h2>
                        Simulateur de <em>salaire</em>
                      </h2>
                    </div>
                  </Col>
                </Row>
                <SalarySimulator />
              </Modal.Body>
              <Modal.Footer>
                <div className="d-grid gap-2" style={{ margin: "auto" }}>
                  <Button
                    size={"lg"}
                    className="sim-btn-cancel"
                    variant="secondary"
                    onClick={handleClose}
                  >
                    Fermer
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <Navbar.Brand href="/" />
    </Navbar>
  );
}
