import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { MainBanner } from "./components/MainBanner";
import { Services } from "./components/Services";
import { WhoAreWe } from "./components/WhoAreWe";
import { OurExpertise } from "./components/OurExpertise";
import { OurNetwork } from "./components/OurNetwork";
import { fingerprintLoad } from "./store/fingerprint/actions";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fingerprintLoad());
  }, [dispatch]);

  return (
    <Container>
      <Header />
      <MainBanner />
      <Services />
      <OurExpertise />
      <OurNetwork />
      <WhoAreWe />
      <Contact />
      <Footer />
    </Container>
  );
}

export default App;
