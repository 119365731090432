import React, { useState } from "react";
import { Container, Col, Row, Modal } from "react-bootstrap";
import { FaLinkedin, FaTwitterSquare, FaFacebookSquare } from "react-icons/fa";

export function Footer() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Container>
      <footer style={{ textAlign: "center" }}>
        <Col lg="12">
          <p>
            Copyright © 2021 A l'Aportage,{" "}
            <a href="/" onClick={handleShow}>
              tous droits réservés
            </a>
            .
            <br />
            Web Designed by{" "}
            <a
              rel="nofollow"
              href="https://templatemo.com"
              title="free CSS templates"
            >
              TemplateMo
            </a>
          </p>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton />
            <Modal.Body>
              <Row>
                <Col lg={{ span: 8, offset: 2 }}>
                  <div
                    className="section-heading wow bounceIn"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <h2>
                      Copyright <em>A L'APORTAGE</em>
                    </h2>
                  </div>
                </Col>
                <div>
                  <h4>Mentions légales</h4>
                  <p>
                    Le site "www.alaportage.fr" est édité par A L’APORTAGE,
                    Société à Responsabilité Limitée au capital de 5 000 euros,
                    immatriculée au Registre du Commerce et des Sociétés de
                    Nanterre sous le numéro 884 508 763, dont le siège social
                    est situé 53 Avenue Gambetta 92400 Courbevoie.
                    <br />
                    <br />
                    <strong>Téléphone :</strong> 06 11 46 22 88.
                    <br />
                    <strong>Adresse email :</strong> contact@alaportage.fr
                    <br />
                    <br />
                    Le directeur de publication du site est Monsieur Nicolas
                    CAUVET.
                    <br />
                    <br />
                  </p>
                  <h4>Droit de reproduction</h4>
                  <p>
                    Tous droits de reproduction et de représentation réservés.{" "}
                    <br />
                    <br />
                    © 2021 A L’APORTAGE Toutes les informations reproduites dans
                    ce site sont protégées par des droits de propriété
                    intellectuelle détenus par A L’APORTAGE.
                    <br />
                    <br />
                    Par conséquent, aucune de ces informations ne peut être
                    reproduite, modifiée, rediffusée, traduite, exploitée
                    commercialement ou réutilisée de quelque manière que ce soit
                    sans l'accord préalable écrit de A L’APORTAGE.
                  </p>
                </div>
              </Row>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
          <p>
            Follow us: <FaLinkedin /> <FaTwitterSquare /> <FaFacebookSquare />
          </p>
        </Col>
      </footer>
    </Container>
  );
}
