import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { SalarySimulatorInput } from "../../queries/query-configs/src";
import { getSalarySimulation } from "../../queries";
import { useMutation } from "redux-query-react";
import { LoadingButton } from "../LoadingButton";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getFingerprintStore } from "../../store/fingerprint/store";

const schema = yup.object().shape({
  averageDailyRate: yup.number().required().min(1).integer(),
  workedDays: yup.number().required().min(1).integer(),
  businessExpenses: yup.number().required().min(0).integer(),
  managementFeesRate: yup.number().required().min(5).max(7).integer(),
  callBackRequest: yup.boolean(),
  email: yup.string().email().when("callBackRequest", {
    is: true,
    then: yup.string().email().required(),
    otherwise: yup.string().email(),
  }),
});

interface Message {
  variant: string;
  content: string;
}

export function SalarySimulatorForm() {
  const fingerprintStore = useSelector(getFingerprintStore);

  function sanitizeSalarySimulatorInput(
    salarySimulatorInput: SalarySimulatorInput
  ) {
    if (salarySimulatorInput.email === "") {
      return {
        ...salarySimulatorInput,
        email: undefined,
        visitorId: fingerprintStore?.visitorId,
      };
    }
    return salarySimulatorInput;
  }
  const [message, setMessage] = useState<Message | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SalarySimulatorInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      averageDailyRate: 500,
      workedDays: 20,
      businessExpenses: 0,
      managementFeesRate: 7,
      email: undefined,
    },
  });

  const [{ isPending, isFinished, status }, sendContactFormData] =
    useMutation(getSalarySimulation);

  const onSubmit = (salarySimulatorInput: SalarySimulatorInput) => {
    sendContactFormData({
      salarySimulatorInput: sanitizeSalarySimulatorInput(salarySimulatorInput),
    });
  };

  useEffect(() => {
    if (!isPending && isFinished && status !== 200) {
      setMessage({
        variant: "danger",
        content: "Oups! Merci de reessayer plus tard!",
      });
      setTimeout(() => setMessage(null), 5000);
    }
  }, [isPending, isFinished, status]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      {message && <Alert variant={message.variant}>{message.content}</Alert>}
      <Row>
        <Form.Group as={Col} controlId="validationSalarySimulatorInputA">
          <Form.Label>TJM</Form.Label>
          <Form.Control
            {...register("averageDailyRate")}
            isInvalid={!!errors.averageDailyRate}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.averageDailyRate?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId="validationSalarySimulatorInputA">
          <Form.Label>Jours travaillés</Form.Label>
          <Form.Control
            {...register("workedDays")}
            isInvalid={!!errors.workedDays}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.workedDays?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="validationSalarySimulatorInputB">
          <Form.Label>Frais professionnels non facturés</Form.Label>
          <Form.Control
            {...register("businessExpenses")}
            isInvalid={!!errors.businessExpenses}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.businessExpenses?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId="validationSalarySimulatorInputB">
          <Form.Label>Frais de gestion</Form.Label>
          <Form.Control
            as="select"
            custom
            {...register("managementFeesRate")}
            isInvalid={!!errors.managementFeesRate}
          >
            <option value={5}>5%</option>
            <option value={6}>6%</option>
            <option value={7}>7%</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors?.managementFeesRate?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="validationSalarySimulatorInputB">
          <Form.Label>Email</Form.Label>
          <Form.Control {...register("email")} isInvalid={!!errors.email} />
          <Form.Control.Feedback type="invalid">
            {errors?.email?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId="validationSalarySimulatorInputB">
          <Form.Group>
            <Form.Label />
            <Form.Check
              type="checkbox"
              label="Etre recontacté ?"
              {...register("callBackRequest")}
            />
          </Form.Group>
        </Form.Group>
      </Row>
      <Form.Group as={Col}>
        <LoadingButton isLoading={isPending} name={"Calculer"} />
      </Form.Group>
    </Form>
  );
}
