import { createReducer } from "@reduxjs/toolkit";
import { fingerprintSet } from "./actions";

interface FingerprintState {
  visitorId: null | string;
}

export const getFingerprintStore = (state: any) => state.fingerprint;
const initialState = { visitorId: null } as FingerprintState;
export const fingerprintReducer = createReducer(initialState, (builder) => {
  builder.addCase(fingerprintSet, (state, action) => {
    state.visitorId = action.payload;
  });
});
