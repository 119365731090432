import { Action } from "redux";
import { put, takeLatest } from "redux-saga/effects";
import {
  fingerprintLoad as fingerprintLoadAction,
  fingerprintSet as fingerprintSetAction,
} from "./actions";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

function* fingerprintLoad(_action: Action) {
  const fingerprint = yield FingerprintJS.load();
  const result = yield fingerprint.get();
  yield put(fingerprintSetAction(result.visitorId));
}

function* fingerprint() {
  yield takeLatest(fingerprintLoadAction.type, fingerprintLoad);
}

export default fingerprint;
