/* eslint-disable */
/**
 * ALP API
 * A l\'aportage API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SalarySimulatorInput
 */
export interface SalarySimulatorInput  {
    /**
     * 
     * @type {string}
     * @memberof SalarySimulatorInput
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SalarySimulatorInput
     */
    callBackRequest: boolean;
    /**
     * 
     * @type {number}
     * @memberof SalarySimulatorInput
     */
    averageDailyRate: number;
    /**
     * 
     * @type {number}
     * @memberof SalarySimulatorInput
     */
    workedDays: number;
    /**
     * 
     * @type {number}
     * @memberof SalarySimulatorInput
     */
    businessExpenses: number;
    /**
     * 
     * @type {number}
     * @memberof SalarySimulatorInput
     */
    managementFeesRate: number;
    /**
     * 
     * @type {string}
     * @memberof SalarySimulatorInput
     */
    visitorId?: string;
}

export function SalarySimulatorInputFromJSON(json: any): SalarySimulatorInput {
    return {
        'email': !exists(json, 'email') ? undefined : json['email'],
        'callBackRequest': json['call_back_request'],
        'averageDailyRate': json['average_daily_rate'],
        'workedDays': json['worked_days'],
        'businessExpenses': json['business_expenses'],
        'managementFeesRate': json['management_fees_rate'],
        'visitorId': !exists(json, 'visitor_id') ? undefined : json['visitor_id'],
    };
}

export function SalarySimulatorInputToJSON(value?: SalarySimulatorInput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'email': value.email,
        'call_back_request': value.callBackRequest,
        'average_daily_rate': value.averageDailyRate,
        'worked_days': value.workedDays,
        'business_expenses': value.businessExpenses,
        'management_fees_rate': value.managementFeesRate,
        'visitor_id': value.visitorId,
    };
}


