import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
  contactContactPost,
  ContactForm as ContactFormData,
} from "../../queries/query-configs/src";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "redux-query-react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { LoadingButton } from "../LoadingButton";
import { getFingerprintStore } from "../../store/fingerprint/store";

const maxMessageLength = 255; // this value must match backend schemas.ContactForm
const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  message: yup.string().min(10).max(maxMessageLength).required(),
});

interface Message {
  variant: string;
  content: string;
}

export function ContactForm() {
  const fingerprintStore = useSelector(getFingerprintStore);
  const [message, setMessage] = useState<Message | null>(null);
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ContactFormData>({ resolver: yupResolver(schema) });

  const watchMessage = watch("message", "");

  const [{ isPending, isFinished, status }, sendContactFormData] =
    useMutation(contactContactPost);

  const onSubmit = (contactForm: ContactFormData) => {
    sendContactFormData({
      contactForm: { ...contactForm, visitorId: fingerprintStore?.visitorId },
    });
  };

  useEffect(() => {
    if (!isPending && isFinished && status === 200) {
      reset();
      setMessage({
        variant: "success",
        content: "Nous avons bien recu votre message!",
      });
      setTimeout(() => setMessage(null), 5000);
    }
    if (!isPending && isFinished && status !== 200) {
      reset();
      setMessage({
        variant: "danger",
        content: "Oups! Merci de reessayer plus tard!",
      });
      setTimeout(() => setMessage(null), 5000);
    }
  }, [isPending, isFinished, status, reset]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      {message && <Alert variant={message.variant}>{message.content}</Alert>}
      <Form.Group controlId="validationName">
        <Row>
          <Col>
            <Form.Control
              placeholder="Nom"
              {...register("name")}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.name?.message}
            </Form.Control.Feedback>
          </Col>
          <Col>
            <Form.Control placeholder="Telephone" {...register("telephone")} />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group controlId="validationEmail">
        <Row>
          <Col>
            <Form.Control
              placeholder="Email"
              {...register("email")}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.email?.message}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Form.Group>
      <Form.Group controlId="validationMessage">
        <Form.Label>Message</Form.Label>
        <Form.Control
          placeholder=""
          {...register("message")}
          isInvalid={!!errors.message}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.message?.message}
        </Form.Control.Feedback>
        <p style={{ textAlign: "right" }}>
          {watchMessage ? watchMessage.length : 0}/{maxMessageLength}
        </p>
      </Form.Group>
      <LoadingButton isLoading={isPending} name={"Envoyer"} />
    </Form>
  );
}
