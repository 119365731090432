import { applyMiddleware, createStore, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { entitiesReducer, queriesReducer, queryMiddleware } from "redux-query";
import superagentInterface from "redux-query-interface-superagent";
import { fingerprintReducer } from "./fingerprint/store";
import fingerprintSaga from "./fingerprint/saga";

// Infer the `RootState` and `AppDispatch` types from the store itself
// TODO just create a type instead of inferring to test
/* eslint-disable */
export type RootState = ReturnType<typeof store.getState>;
/* eslint-enable */
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// TODO remove any in favor of RootState somehow
export const getQueries = (state: any) => state.queries;
export const getEntities = (state: any) => state.entities;

const reducer = combineReducers({
  entities: entitiesReducer,
  queries: queriesReducer,
  fingerprint: fingerprintReducer,
});

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  reducer,
  applyMiddleware(
    sagaMiddleware,
    queryMiddleware(superagentInterface, getQueries, getEntities)
  )
);

sagaMiddleware.run(fingerprintSaga);

export default store;
