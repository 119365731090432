import {
  SalarySimulatorOutput,
  salarySimulatorSalarySimulatorPost,
  SalarySimulatorSalarySimulatorPostRequest,
} from "./query-configs/src";

export function getSalarySimulation(
  requestParameters: SalarySimulatorSalarySimulatorPostRequest
) {
  const requestConfig = {
    transform: (salarySimulator: SalarySimulatorOutput) => {
      return {
        salarySimulator,
      };
    },
    update: {
      salarySimulator: (
        oldValue: SalarySimulatorOutput,
        newValue: SalarySimulatorOutput
      ) => newValue,
    },
  };
  return salarySimulatorSalarySimulatorPost(requestParameters, requestConfig);
}
