import React from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { Accordion } from "react-bootstrap";

export function Services() {
  return (
    <Container id="services" className="our-services section">
      <Row>
        <Col lg={{ span: 6, offset: 3 }}>
          <div
            className="section-heading wow bounceIn animated"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <h6>Nos Services</h6>
            <h2>
              Nous assurons pour <span>vous</span> <em>Liberté, Sécurité </em>et{" "}
              <em>Réactivité</em>
            </h2>
          </div>
        </Col>
      </Row>

      <Accordion>
        <Row>
          <Col xs={6} md={3}>
            <Card
              className="wow bounceInUp animated"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <Card.Header>
                <Accordion.Toggle eventKey="0">
                  <Col className="AccordionImg img-contratcadre" lg="4" />
                  <Col className="AccordionTitle" lg="8">
                    <div className="right-content">
                      <h4>Statut cadre & contrat de travail</h4>
                    </div>
                  </Col>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse className="AccordionDesc" eventKey="0">
                <div>
                  <div className="line-dec" />
                  <p>
                    Nous vous proposons la sécurité d’un contrat à durée
                    indéterminée avec le statut Cadre.
                  </p>
                </div>
              </Accordion.Collapse>
            </Card>
          </Col>

          <Col xs={6} md={3}>
            <Card
              className="wow bounceInUp animated"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <Card.Header>
                <Accordion.Toggle eventKey="1">
                  <Col className="AccordionImg img-aidejuridique" lg="4" />
                  <Col className="AccordionTitle" lg="8">
                    <div className="right-content">
                      <h4>Aide juridique</h4>
                    </div>
                  </Col>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse className="AccordionDesc" eventKey="1">
                <div>
                  <div className="line-dec" />
                  <p>
                    Notre service juridique vous apportera des réponses sur les
                    sujets souhaités professionnels ou personnels.
                  </p>
                </div>
              </Accordion.Collapse>
            </Card>
          </Col>

          <Col xs={6} md={3}>
            <Card
              className="wow bounceInUp animated"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <Card.Header>
                <Accordion.Toggle eventKey="2">
                  <Col className="AccordionImg img-admsimplify" lg="4" />
                  <Col className="AccordionTitle" lg="8">
                    <div className="right-content">
                      <h4>Gestion administrative simplifiée</h4>
                    </div>
                  </Col>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse className="AccordionDesc" eventKey="2">
                <div>
                  <div className="line-dec" />
                  <p>
                    Chaque fin de mois, vous nous remettrez le compte-rendu
                    d’activé (co-signé avec votre client) par mail et déclarerez
                    vos frais professionnels via notre plateforme
                    dématérialisée.
                    <br />
                    Ce seront les seules actions qui seront à mener. Nous nous
                    chargerons de toutes les déclarations administratives et
                    éditerons votre salaire. De même, si vous avez un arrêt
                    maladie, nous prendrons en charge la déclaration à votre
                    CPAM.
                  </p>
                </div>
              </Accordion.Collapse>
            </Card>
          </Col>

          <Col xs={6} md={3}>
            <Card
              className="wow bounceInUp animated"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <Card.Header>
                <Accordion.Toggle eventKey="3">
                  <Col className="AccordionImg img-garantie" lg="4" />
                  <Col className="AccordionTitle" lg="8">
                    <div className="right-content">
                      <h4>Garantie Financière</h4>
                    </div>
                  </Col>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse className="AccordionDesc" eventKey="3">
                <div>
                  <div className="line-dec" />
                  <p>
                    Cette dernière est une obligation légale. Elle permet de
                    s’assurer du paiement des rémunérations et des indemnités du
                    consultant porté. Elle garantit également le règlement des
                    cotisations sociales auprès des organismes en cas de
                    défaillance de la société de portage salarial.
                  </p>
                </div>
              </Accordion.Collapse>
            </Card>
          </Col>
        </Row>

        <Row xs={2} md={4}>
          <Col xs={6} md={3}>
            <Card
              className="wow bounceInUp animated"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <Card.Header>
                <Accordion.Toggle eventKey="4">
                  <Col className="AccordionImg img-fraispro" lg="4" />
                  <Col className="AccordionTitle" lg="8">
                    <div className="right-content">
                      <h4>Gestion des frais professionnels</h4>
                    </div>
                  </Col>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse className="AccordionDesc" eventKey="4">
                <div>
                  <div className="line-dec" />
                  <p>
                    Nous assurons la gestion de vos frais professionnels à
                    facturer au client (les frais liés à la mission) comme ceux
                    non facturés au client (les frais de fonctionnement liés à
                    votre activité).
                  </p>
                </div>
              </Accordion.Collapse>
            </Card>
          </Col>

          <Col xs={6} md={3}>
            <Card
              className="wow bounceInUp animated"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <Card.Header>
                <Accordion.Toggle eventKey="5">
                  <Col className="AccordionImg img-assurancepro" lg="4" />
                  <Col className="AccordionTitle" lg="8">
                    <div className="right-content">
                      <h4>Assurance professionnelle</h4>
                    </div>
                  </Col>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse className="AccordionDesc" eventKey="5">
                <div>
                  <div className="line-dec" />
                  <p>
                    Nous avons souscrit à une assurance Responsabilité
                    Professionnelle qui vous couvrira pour tous les dommages
                    causés aux tiers et pour chacune de vos missions.
                  </p>
                </div>
              </Accordion.Collapse>
            </Card>
          </Col>

          <Col xs={6} md={3}>
            <Card
              className="wow bounceInUp animated"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <Card.Header>
                <Accordion.Toggle eventKey="7">
                  <Col className="AccordionImg img-mutuelle" lg="4" />
                  <Col className="AccordionTitle" lg="8">
                    <div className="right-content">
                      <h4>Couverture auprès de notre mutuelle</h4>
                    </div>
                  </Col>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse className="AccordionDesc" eventKey="7">
                <div>
                  <div className="line-dec" />
                  <p>
                    Nous vous proposerons une mutuelle avantageuse à un prix
                    négocié auprès de notre partenaire Axa.
                  </p>
                </div>
              </Accordion.Collapse>
            </Card>
          </Col>

          <Col xs={6} md={3}>
            <Card
              className="wow bounceInUp animated"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <Card.Header>
                <Accordion.Toggle eventKey="8">
                  <Col className="AccordionImg img-expatriation" lg="4" />
                  <Col className="AccordionTitle" lg="8">
                    <div className="right-content">
                      <h4>Détachement à l'étranger</h4>
                    </div>
                  </Col>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse className="AccordionDesc" eventKey="8">
                <div>
                  <div className="line-dec" />
                  <p>
                    Si votre mission vous amène à quitter temporairement le
                    territoire national, nous ferons les déclarations
                    nécessaires auprès de la CPAM afin que vous puissiez
                    toujours cotiser sur le sol français.
                  </p>
                </div>
              </Accordion.Collapse>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xs={6} md={{ span: 3, offset: 3 }}>
            <Card
              className="wow bounceInUp animated"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <Card.Header>
                <Accordion.Toggle eventKey="9">
                  <Col className="AccordionImg img-svcadmdedie" lg="4" />
                  <Col className="AccordionTitle" lg="8">
                    <div className="right-content">
                      <h4>Service dédié à vos démarches</h4>
                    </div>
                  </Col>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse className="AccordionDesc" eventKey="9">
                <div>
                  <div className="line-dec" />
                  <p>
                    Un Chargé de compte vous sera attribué tout au long de votre
                    parcours dans notre société. Il sera facilement joignable
                    par téléphone ou par mail dès que vous aurez en besoin. Il
                    sera présent pour répondre à chacune de vos questions liées
                    à votre statut de porté salarié.
                  </p>
                </div>
              </Accordion.Collapse>
            </Card>
          </Col>

          <Col xs={6} md={{ span: 3 }}>
            <Card
              className="wow bounceInUp animated"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <Card.Header>
                <Accordion.Toggle eventKey="10">
                  <Col className="AccordionImg img-catalogue" lg="4" />
                  <Col className="AccordionTitle" lg="8">
                    <div className="right-content">
                      <h4>Catalogue de services en ligne</h4>
                    </div>
                  </Col>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse className="AccordionDesc" eventKey="10">
                <div>
                  <div className="line-dec" />
                  <p>
                    Il est actuellement en construction. Il vous sera proposé
                    des services à la carte payants qui pourront vous aider à
                    chaque étape de votre vie (Calcul de la retraite, Conseil
                    sur l&apos;assurance chômage…).
                  </p>
                </div>
              </Accordion.Collapse>
            </Card>
          </Col>
        </Row>
      </Accordion>
    </Container>
  );
}
