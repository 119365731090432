/* eslint-disable */
/**
 * ALP API
 * A l\'aportage API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ContactForm,
    ContactFormFromJSON,
    ContactFormToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    SalarySimulatorInput,
    SalarySimulatorInputFromJSON,
    SalarySimulatorInputToJSON,
    SalarySimulatorOutput,
    SalarySimulatorOutputFromJSON,
    SalarySimulatorOutputToJSON,
} from '../models';

export interface ContactContactPostRequest {
    contactForm: ContactForm;
}

export interface SalarySimulatorSalarySimulatorPostRequest {
    salarySimulatorInput: SalarySimulatorInput;
}


/**
 * contact
 */
function contactContactPostRaw<T>(requestParameters: ContactContactPostRequest, requestConfig: runtime.TypedQueryConfig<T, any> = {}): QueryConfig<T> {
    if (requestParameters.contactForm === null || requestParameters.contactForm === undefined) {
        throw new runtime.RequiredError('contactForm','Required parameter requestParameters.contactForm was null or undefined when calling contactContactPost.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/contact`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || ContactFormToJSON(requestParameters.contactForm),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        throw "OH NO";
    }

    return config;
}

/**
* contact
*/
export function contactContactPost<T>(requestParameters: ContactContactPostRequest, requestConfig?: runtime.TypedQueryConfig<T, any>): QueryConfig<T> {
    return contactContactPostRaw(requestParameters, requestConfig);
}

/**
 * simulator
 */
function salarySimulatorSalarySimulatorPostRaw<T>(requestParameters: SalarySimulatorSalarySimulatorPostRequest, requestConfig: runtime.TypedQueryConfig<T, SalarySimulatorOutput> = {}): QueryConfig<T> {
    if (requestParameters.salarySimulatorInput === null || requestParameters.salarySimulatorInput === undefined) {
        throw new runtime.RequiredError('salarySimulatorInput','Required parameter requestParameters.salarySimulatorInput was null or undefined when calling salarySimulatorSalarySimulatorPost.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/salary_simulator`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || SalarySimulatorInputToJSON(requestParameters.salarySimulatorInput),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SalarySimulatorOutputFromJSON(body), text);
    }

    return config;
}

/**
* simulator
*/
export function salarySimulatorSalarySimulatorPost<T>(requestParameters: SalarySimulatorSalarySimulatorPostRequest, requestConfig?: runtime.TypedQueryConfig<T, SalarySimulatorOutput>): QueryConfig<T> {
    return salarySimulatorSalarySimulatorPostRaw(requestParameters, requestConfig);
}

