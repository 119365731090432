import React, { useState } from "react";
import { Modal, Button, Row, Col, Container } from "react-bootstrap";
import { Customers } from "./Customers";
export function WhoAreWe() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Container id={"about"} className="about-us section">
      <Row>
        <Col lg="6">
          <div
            className="left-image wow fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          />
        </Col>
        <Col lg="6">
          <div
            className="right-wording align-self-center wow fadeInRight"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            <div className="section-heading">
              <h6>Qui-sommes-nous ?</h6>
              <h2>
                Société de <em>portage salarial</em> dynamique
              </h2>
            </div>

            <p>
              A L’APORTAGE vous guide (quelque soit votre profil …) dans vos
              projets. Nous vous offrons des solutions adaptées, via un statut
              salarié, pour assurer la gestion de vos activités et développer
              vos compétences. A L’APORTAGE vous mettra en valeur dans votre
              spécialité et vous donnera accès à son réseau de clients et
              partenariats afin de faciliter vos recherches de mission. Ce qui
              compte le plus à nos yeux ? La relation humaine. Construisons-la
              ensemble !
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className="main-header-button"
                variant="primary"
                onClick={handleShow}
              >
                Ils nous font confiance
              </Button>
            </div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton />
              <Modal.Body>
                <Row>
                  <Col lg={{ span: 8, offset: 2 }}>
                    <div
                      className="section-heading wow bounceIn"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                    >
                      <h2>
                        Ils nous font <em>confiance</em>
                      </h2>
                    </div>
                  </Col>
                </Row>
                <Customers />
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
