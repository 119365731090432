import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaFacebookSquare, FaLinkedin, FaTwitterSquare } from "react-icons/fa";
import { ContactForm } from "./ContactForm";

export function Contact() {
  return (
    <Container className="contact-us section">
      <Row>
        <Col lg="12" className="wow fadeInUp animated">
          <div id="contact">
            <Col lg={{ span: 6, offset: 3 }}>
              <div className="section-heading">
                <h6>Contactez nous</h6>
                <h2>
                  <span>Remplissez</span> le formulaire, nous prendrons{" "}
                  <em>contact</em> avec vous
                </h2>
              </div>
            </Col>

            <Col lg="9" id="contact-form">
              <ContactForm />
            </Col>
            <Col lg="3" id="contact-info">
              <div className="contact-info">
                <ul>
                  <li>
                    <div className="email-icon" />
                    <a href="mailto:contact@alaportage.fr">
                      contact@alaportage.fr
                    </a>
                  </li>
                  <li>
                    <div className="phone-icon" />
                    <a href="tel:0146950113">01 46 95 01 13</a>
                  </li>
                  <li>
                    <div className="location-icon" />
                    <a
                      href="https://www.google.com/maps/place/53+Av.+Gambetta,+92400+Courbevoie/@48.8934483,2.2443147,17z/data=!3m1!4b1!4m5!3m4!1s0x47e66508aa076875:0x37e025dd848ec0c!8m2!3d48.8934448!4d2.2465034"
                      target="_blank"
                      rel="noreferrer"
                    >
                      53 avenue Gambetta, 92400 COURBEVOIE
                    </a>
                  </li>
                  <li>
                    <FaLinkedin /> <FaTwitterSquare /> <FaFacebookSquare />
                  </li>
                </ul>
              </div>
            </Col>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
