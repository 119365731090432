/* eslint-disable */
/**
 * ALP API
 * A l\'aportage API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SalarySimulatorOutput
 */
export interface SalarySimulatorOutput  {
    /**
     * 
     * @type {number}
     * @memberof SalarySimulatorOutput
     */
    revenue: number;
    /**
     * 
     * @type {number}
     * @memberof SalarySimulatorOutput
     */
    managementFees: number;
    /**
     * 
     * @type {number}
     * @memberof SalarySimulatorOutput
     */
    grossSalary: number;
    /**
     * 
     * @type {number}
     * @memberof SalarySimulatorOutput
     */
    netSalaryWithManagementFees: number;
    /**
     * 
     * @type {number}
     * @memberof SalarySimulatorOutput
     */
    netSalaryWithoutManagementFees: number;
    /**
     * 
     * @type {number}
     * @memberof SalarySimulatorOutput
     */
    totalSocialCharges: number;
    /**
     * 
     * @type {number}
     * @memberof SalarySimulatorOutput
     */
    employerCost: number;
    /**
     * 
     * @type {number}
     * @memberof SalarySimulatorOutput
     */
    totalEmployerExpenses: number;
    /**
     * 
     * @type {number}
     * @memberof SalarySimulatorOutput
     */
    percentagePaid: number;
}

export function SalarySimulatorOutputFromJSON(json: any): SalarySimulatorOutput {
    return {
        'revenue': json['revenue'],
        'managementFees': json['management_fees'],
        'grossSalary': json['gross_salary'],
        'netSalaryWithManagementFees': json['net_salary_with_management_fees'],
        'netSalaryWithoutManagementFees': json['net_salary_without_management_fees'],
        'totalSocialCharges': json['total_social_charges'],
        'employerCost': json['employer_cost'],
        'totalEmployerExpenses': json['total_employer_expenses'],
        'percentagePaid': json['percentage_paid'],
    };
}

export function SalarySimulatorOutputToJSON(value?: SalarySimulatorOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'revenue': value.revenue,
        'management_fees': value.managementFees,
        'gross_salary': value.grossSalary,
        'net_salary_with_management_fees': value.netSalaryWithManagementFees,
        'net_salary_without_management_fees': value.netSalaryWithoutManagementFees,
        'total_social_charges': value.totalSocialCharges,
        'employer_cost': value.employerCost,
        'total_employer_expenses': value.totalEmployerExpenses,
        'percentage_paid': value.percentagePaid,
    };
}


